.ant-layout {
    background: transparent;
}

.ant-menu-horizontal {
    border-bottom: none;
}

.ant-layout-header {
    background-color: transparent;
    line-height: inherit;
    height: inherit;
}

.ant-menu-light.ant-menu-horizontal >.ant-menu-item-selected::after{
    border-bottom-color: transparent;
}

h1.ant-typography,
h2.ant-typography,
h3.ant-typography,
h4.ant-typography,
h5.ant-typography{
    padding: 0 !important;
    margin: 0 !important;
}

.ant-typography {
    font-family: 'Poppins', sans-serif !important;
}
.ant-layout-footer{
    padding: 0;
    background: transparent;
}

a {
    text-decoration: none;
    color: inherit;
}

a:hover{
    text-decoration: none;
    color: inherit;
}