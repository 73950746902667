body{
  padding: 0;
  margin: 0;
}

h3, h4, h5 {
  font-weight: 500;
}
p, h1, h2, h3, h4, h5, label, span, li {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}

a {
  color: black;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  -webkit-text-decoration-skip: objects;
}

html {
  scroll-behavior: smooth;
}


/*----------------Кнопка Отмена в модальном окне------------------*/
.custom-cancel-btn {
  border-color: grey;
}

.custom-cancel-btn:hover {
  border-color: #44A92E !important;
  color: #387C35 !important;
}
/*----------------Кнопка Отмена Конец------------------*/

.custom-menu-item-last::after {
  border-bottom-color: transparent !important;
  border-bottom:none !important;
}

.burger-btn {
  width: 30px;
  height: 30px;
  margin-left: 20px;
  position: relative;
  cursor: pointer;
}

.burger-btn:before {
  content: '';
  position: absolute;
  top: 0;
  width: 30px;
  height: 2px;
  background-color: black;
}

.burger-btn:after {
  content: '';
  position: absolute;
  top: 0;
  width: 30px;
  height: 2px;
  background-color: black;
}

